import {React, useState, useEffect} from 'react'

//Firebase
import db from 'firebase'
import { collection,onSnapshot, query, getDocs } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
// material-ui icons
import Menu from "@material-ui/icons/Menu";
import ViewList from "@material-ui/icons/ViewList";
import Notifications from "@material-ui/icons/Notifications";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
const useStyles = makeStyles(styles);

const NotificationRef = collection(db, "APB_Notification");

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  const [openNotification, setOpenNotification] = useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  
  const [notificationTotal, setNotificationTotal] = useState(0)
  const [notificationMessage, setNotificationMessage] = useState(["1"])
  const [currentUserEmail, setCurrentUserEmail] = useState()
  const [currentUserName, setCurrentUserName] = useState()
  const [currentUser, setCurrentUse] = useState()
 
  useEffect(() => {
    getUserProfile()
    //const interval = setInterval(() => getUserProfile(), 10000);
    //return () => {clearInterval(interval)};
    
  },[])

  useEffect(()=>{
    fetchNotification()
    const interval = setInterval(() => fetchNotification(), 60000);
    return () => {clearInterval(interval)};
  },[])

  const getUserProfile = () =>{
    const auth = getAuth()
   // console.log("AdminNav",auth.currentUser.displayName)
    setCurrentUserEmail(auth.currentUser.email)
    setCurrentUserName(auth.currentUser.displayName)
  }
/*
  const fetchNotification = async () => {
    
    const querySnapshot = await  getDocs(NotificationRef)
    setNotificationTotal(querySnapshot.size)   
    console.log("quert Size:", querySnapshot.size)
    var message = []
    querySnapshot.forEach((doc) => {
      console.log("noification size", doc.data().message)
    
      message.push(
      (<MenuItem onClick={handleCloseNotification} className={dropdownItem}>
        <small style={{color:'red', fontWeight:'bold'}}>{doc.data().message}</small>
      </MenuItem>)
      )
    
    })
    //console.log(message)
    if (message.length > 0){
    setNotificationMessage(message)
    }else{
      setNotificationMessage((<MenuItem onClick={handleCloseNotification} className={dropdownItem}>
        <small style={{color:'grey', fontWeight:'bold'}}>You don't have alert</small>
      </MenuItem>))
    }
  }
  */
  const fetchNotification =   () =>  {
  const auth = getAuth()
    const unsubscribe =  onSnapshot(NotificationRef, (querySnapshot) => {
      //console.log("querydoc size",queryDoc.size)
      setNotificationTotal(querySnapshot.size)   
      var message = []
      querySnapshot.forEach((doc) => {
        //console.log("noification size", doc.data().message)
      
        message.push(
        (<MenuItem onClick={handleCloseNotification} className={dropdownItem}>
          <small style={{color:'red', fontWeight:'bold'}}>{doc.data().message}</small>
        </MenuItem>)
        )
      
      })
      //console.log(message)
      if (message.length > 0){
      setNotificationMessage(message)
      }else{
        setNotificationMessage((<MenuItem onClick={handleCloseNotification} className={dropdownItem}>
          <small style={{color:'grey', fontWeight:'bold'}}>You don't have alert</small>
        </MenuItem>))
      }
    }, (error)=>{console.log("Error", error) ;unsubscribe()})
  }

      
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
       
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              
              <div></div> 
             /* <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button> */
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>

     
       
        
        <div >
          <Button
            color="transparent"
            aria-label="Logout"
            justIcon
            aria-haspopup="true"
            onClick = {handleClickNotification}
            className={classes.buttonLink}
            muiClasses={{
              label:  "",
            }}
          >
            <Notifications className={classes.headerLinksSvg + " " + classes.links}/>
            
            {(notificationTotal == "0")?(<div></div>):(<span className={classes.notifications}>{notificationTotal}</span>)}

          
          </Button>
        
       
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
          
        </Hidden>
      </Toolbar>
      <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">

                   {notificationMessage}

                  {/*
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
       
                    <p style={{color:'red', fontWeight:'bold'}}>Female toilet water leakage alert at 9/F Location 1</p>
                    </MenuItem>
                  */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <span  style={{fontSize:10,position:'relative',top:-60,left:45}}>Welcome {(!currentUserName)?(currentUserEmail):(currentUserName)}</span>
    </AppBar>
    
    
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
