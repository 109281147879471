import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

//Firebase
import { useCallback } from 'react'
import firebase from "firebase";
import { getAuth, signOut } from "firebase/auth";


// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState()

/* 
//Log out user if no email verify or not in whitelist
  React.useEffect(()=>{
    const auth = getAuth()
    const user = auth.currentUser;
    console.log("display name ", user.displayName, " email ", user.email, " emailVerified ",user.emailVerified )
    if (user.emailVerified == false){
      signOut(auth)
      
    }
  },[])
  */

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

    //handle logout submit
 const handleSignOut = useCallback(async e => {
      e.preventDefault()
  
      //const { email, password } = e.target.elements
      //console.log("email:" ,email, "password",password)
      const auth = getAuth()
      try {
        await signOut(auth)
      } catch (e) {
        alert(e.message)
      }
    }, [])

  return (
    <div className={wrapper}>
      {/*
      <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Logout"
            justIcon
            aria-haspopup="true"
            onClick={handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <Notifications className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }/>
            
            
             <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
            Notification
            </span>
          </Hidden>
          
          <Hidden smDown implementation="css">
            <span className={classes.notifications}>5</span>
          </Hidden>
          
          </Button>
        </div>
        */}
      <div className={managerClasses}>

      <Button
          color="transparent"
          aria-label="Logout"
          justIcon
         
          aria-haspopup="true"
          onClick={handleSignOut}
          className={classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <ExitToAppIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
           <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
           <span className={classes.linkText}> Logout </span>
            </span>
          </Hidden>
          
        </Button>
        </div>

      
      </div>

  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
