import React from "react";

//Firebase
import { useCallback } from 'react'
import firebase from "firebase";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink ,updatePassword} from "firebase/auth";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import PersonIcon from '@material-ui/icons/Person';
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Popper from '@material-ui/core/Popper';

//Password Checker
import {passwordStrength} from 'variables/checkPassword'

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);



export default function ResetPasswordPage() {
    // register form
    const [registerErrMsg, setregisterErrMsg] = React.useState("")
    const [registerEmail, setregisterEmail] = React.useState("");
    const [registerEmailState, setregisterEmailState] = React.useState("");
    const [registerPassword, setregisterPassword] = React.useState("");
    const [registerPasswordState, setregisterPasswordState] = React.useState("");
    const [registerPasswordStrength, setregisterPasswordStrength] = React.useState("");
    const [registerConfirmPassword, setregisterConfirmPassword] = React.useState("");
    const [registerConfirmPasswordState, setregisterConfirmPasswordState,] = React.useState("");
    const [registerCheckbox, setregisterCheckbox] = React.useState(false);
    const [registerCheckboxState, setregisterCheckboxState] = React.useState("");
    const [registerPasswordHelper, setregisterPasswordHelper] = React.useState("")
  
  React.useEffect(()=>{
    //emailLinkSignIn()
  },[])

  const emailLinkSignIn = () => {
   // console.log("emailLinkSign register password:",registerPassword)
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {

          //console.log("new register password:",registerPassword)
          //Set Password
          //const auth = getAuth();
          const user = auth.currentUser;
          //console.log(auth.currentUser.email)
          //const newPassword = registerPassword;
          const newPassword = registerPassword;
             updatePassword(user, newPassword).then(() => {
              // Update successful.
              //console.log("password updated", registerPassword)
            }).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              //console.log("error code ",errorCode , "error Message ",errorMessage, )
            });    

            window.localStorage.removeItem('emailForSignIn');
        })
        .catch((error) => {
          console.log("error",error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
                  // Clear email from storage.
                
    }
  }


    // function that returns true if value is email, false otherwise
    const verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    };

     // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  
  // function Password Strength
  const verifyPasswordStrength = (value) => {
   
    if (passwordStrength(value).id >= 2 && value.length <16  && value.length > 7 ) {
      return true;
    }else {

    return false;
    
  
    }
  };


  const registerClick = () => {
    
    if (registerPasswordState === "") {
      setregisterPasswordState("error");
    }
    if (registerConfirmPasswordState === "") {
      setregisterConfirmPasswordState("error");
    }
  }

  const verifyForm = () =>{ 
   if(registerPasswordState == "success"  && registerConfirmPasswordState == "success"){
          return false} 
    else 
          return true
  }


  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={6}>
          <Card className={classes.cardSignup}>
            <h4 className={classes.cardTitle}>Reset Password</h4>
            <CardBody>
                    <CustomInput
                          success={registerPasswordState === "success"}
                          error={registerPasswordState === "error"}
                          labelText="Password *"
                          id="registerpassword"
                          helperText= {registerPasswordHelper}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              
                              if (verifyPasswordStrength(event.target.value)) {
                                setregisterPasswordState("success");
                                setregisterPasswordHelper("")
                                
                              } else {
                                setregisterPasswordState("error");
                                if (event.target.value.length >15){setregisterPasswordHelper("Password should be less then 16 characters")}
                                else{
                                  setregisterPasswordHelper("8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character")
                                }
                              }
                              setregisterPassword(event.target.value);
                              //console.log("input password:",registerPassword )
                              setregisterPasswordStrength(passwordStrength(event.target.value).value)
                            },
                            type: "password",
                            autoComplete: "off",
                          
                            endAdornment: (

                              <InputAdornment
                                position="end"
                                className={classes.inputAdornment}
                              >
                                {({registerPasswordStrength} == "Weak" ||{registerPasswordStrength} == "Too weak" )
                                    ?(<span  Color="red" style={{fontSize:10, fontWeight: "bold", position:'relative',top:5,right:10}}>{registerPasswordStrength}</span>)
                                    :(<span style={{fontSize:10, position:'relative',top:5,right:10}}>{registerPasswordStrength}</span>)
                                }
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            type: "password",
                            autoComplete: "off",
                          
                          }}
                    />
         
                    <CustomInput
                        success={registerConfirmPasswordState === "success"}
                        error={registerConfirmPasswordState === "error"}
                        labelText="Confirm Password *"
                        id="registerconfirmpassword"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            if (event.target.value ==""){
                              setregisterConfirmPasswordState("error");
                            }
                            if (registerPassword === event.target.value) {
                              setregisterConfirmPasswordState("success");
                            } else {
                              setregisterConfirmPasswordState("error");
                            }
                            setregisterConfirmPassword(event.target.value);
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          autoComplete: "off",
                          
                        }}
                      />
                     
                      <span style={{fontSize:10,position:'relative',left:12}}>*Required fields</span> 
      
                    <div className={classes.center}>
                      <h6 style={{color:"red"}}>{registerErrMsg}</h6>
                    </div>
                    <div className={classes.center}>
                    <Button disabled = {verifyForm()} type="submit" onClick={()=> emailLinkSignIn()} round style={{backgroundColor:"#b61d22"}}>
                       Submit
                      </Button>
                     
                    </div>  
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
