import {React, useEffect, useState} from "react";

//Firebase
import db from 'firebase'
import { collection, onSnapshot } from "firebase/firestore";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Divider from '@material-ui/core/Divider';

//Custom Icon
import {ReactComponent as MaleToiletIcon} from "components/Icon/MaleToilet.svg"
import {ReactComponent as FemaleToiletIcon} from "components/Icon/FemaleToilet.svg"
import {ReactComponent as AccessibleToiletIcon} from "components/Icon/DA.svg"
import {BsFillCircleFill} from "react-icons/bs"



//QuickViews
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

//Styles
const useStyles = makeStyles(styles);
const useStylesButton = makeStyles(buttonsStyle)

export default function CubicleStatus(props) {

  

  //Male Toilet
  const [tableListMale9, setTableListMale9] =useState([])
  const [tableListMale8, setTableListMale8] =useState([])
  
  //Female Toilet
  const [tableListFemale9, setTableListFemale9] =useState([])
  const [tableListFemale8, setTableListFemale8] =useState([])


  //Accessible Toilet
  const [tableListAccessible9, setTableListAccessible9] =useState([])
  const [tableListAccessible8, setTableListAccessible8] =useState([])

  const CubicleStatusRef = collection(db, "APB_Cubicle_Status");


  const handleClick = (floor) =>{
    if (floor == "9" || floor == "8") {setCurrentFloor(floor)}
    //alert("you've clicked floorview "+ floor)
  }
  const handleType = ((type =>{
    setCurrentToiletType(type)
  }))


  useEffect(() =>{

    fetchDataList()
    
  },[])


  const fetchDataList =   () =>  {
    

    var tempListMale9 = []
    var tempListFemale9 = []
    var tempListAccessible9 = []
  
    var tempListMale8 = []
    var tempListFemale8 = []
    var tempListAccessible8 = []
     
        const unsubscribe =  onSnapshot(CubicleStatusRef, (querySnapshot) => {

          console.log("new update")
          querySnapshot.forEach((doc) => {
         
           
             //console.log("floor", doc.data().floor)
             
              
              switch (doc.data().floor){
                case "9":{
                  //console.log("case 9",)
                  
                  updateTableList("9","male",setTableListMale9, tempListMale9, 5 )
                  updateTableList("9","female",setTableListFemale9, tempListFemale9 ,4)
                  updateTableList("9", "zaccessible",setTableListAccessible9, tempListAccessible9 ,1)
        
                  break
                }
                case "8":{
                  //console.log("case 8")
                  updateTableList("8","male",setTableListMale8, tempListMale8, 5 )
                  updateTableList("8","female",setTableListFemale8, tempListFemale8 ,4)
                  updateTableList("8","zaccessible",setTableListAccessible8, tempListAccessible8 ,1)
                  break
                }

              }

            
              function updateTableList(floor, type,setTableList, tempList ,count){
                //console.log("update", type)
                
                if (doc.data().type == type ){
                      tempList.push([doc.data().cubicleId, doc.data().status, doc.data().type])  
                    //tempListMale.push(displayCubicleStatus(doc.data().status, doc.data().cubicleId))
                      tempList.sort(); //console.log( "tempList.sort()", tempList)
                      switch (doc.data().type){
                        case "male":{
                        if (tempList.length >= count){setTableList(tempList)}; //console.log("tableListMale9",tableListMale9)}
                        }
                        case "female":{
                          if (tempList.length >= count){setTableList(tempList)}; //console.log("tableListMale9",tableListMale9)}
                          }
                        case "zaccessible":{
                          if (tempList.length >= count){setTableList(tempList)}; //console.log("tableListMale9",tableListMale9)}
                          }
                      }
                }
              }
                      
                  
            
            })
         
             
          
          
          })
          return () => unsubscribe()
        }
      
    
  const displayCubicleStatus = (status, index) => {
   
      if (status === 'success'){
        return (<GridItem key={index} ><center>{index}</center><BsFillCircleFill  style = {{color:"green"  }}/></GridItem>)
        }else if (status == 'danger') {
        return (<GridItem key={index} ><center>{index}</center><BsFillCircleFill style = {{color: 'red'}}/></GridItem>)
      } else 
        return (<GridItem key={index}><center>{index}</center><BsFillCircleFill style = {{color: 'grey'}}/></GridItem>)
    }
   
  
  return (
    <div>
      <Divider />

      <GridContainer >
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" >
                <h3>9/F</h3>
              </CardIcon>
              </CardHeader>

                <CardBody >
                <GridContainer >  
                 <GridItem><MaleToiletIcon style={{height:"auto",width:"75%", backgroundColor:"lightblue",paddingRight:22, paddingLeft:22, paddingTop:11, paddingBottom:11,borderRadius:30}}/></GridItem>
                 {tableListMale9.map(doc => displayCubicleStatus(doc[1],doc[0]))}    
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><FemaleToiletIcon style={{height:"auto",width:"71%", backgroundColor:"pink",paddingRight:20, paddingLeft:20, paddingTop:10, paddingBottom:10,borderRadius:30}}/></GridItem>
                  {tableListFemale9.map(doc => displayCubicleStatus(doc[1],doc[0]))}
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><AccessibleToiletIcon style={{height:"auto",width:"65%", backgroundColor:"#8AEEBC",paddingRight:15, paddingLeft:15, paddingTop:12, paddingBottom:12,borderRadius:30}}/></GridItem>
                  <div style={{position:"relative", left:-8}}>{tableListAccessible9.map(doc => displayCubicleStatus(doc[1],doc[0]))}</div>
                </GridContainer>
                
              </CardBody>
    
          </Card>
        </GridItem>


   
             <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" >
                <h3>8/F</h3>
              </CardIcon>
          
            </CardHeader>

            <CardBody >
                <GridContainer >  
                 <GridItem><MaleToiletIcon style={{height:"auto",width:"75%", backgroundColor:"lightblue",paddingRight:22, paddingLeft:22, paddingTop:11, paddingBottom:11,borderRadius:30}}/></GridItem>
                 {tableListMale8.map(doc => displayCubicleStatus(doc[1],doc[0]))}    
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><FemaleToiletIcon style={{height:"auto",width:"71%", backgroundColor:"pink",paddingRight:20, paddingLeft:20, paddingTop:10, paddingBottom:10,borderRadius:30}}/></GridItem>
                  {tableListFemale8.map(doc => displayCubicleStatus(doc[1],doc[0]))}
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><AccessibleToiletIcon style={{height:"auto",width:"65%", backgroundColor:"#8AEEBC",paddingRight:15, paddingLeft:15, paddingTop:12, paddingBottom:12,borderRadius:30}}/></GridItem>
                  <div style={{position:"relative", left:-8}}>{tableListAccessible8.map(doc => displayCubicleStatus(doc[1],doc[0]))}</div>
                </GridContainer>
                
              </CardBody>
    
          </Card>
        </GridItem>
          
      
    
    

      
 
             <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" >
                <h3>7/F</h3>
              </CardIcon>
          
            </CardHeader>

              <CardBody style={{justifyContent: "flex-start"}}>
                <GridContainer>
                 <GridItem><MaleToiletIcon style={{height:"auto",width:"75%", backgroundColor:"lightblue",paddingRight:22, paddingLeft:22, paddingTop:11, paddingBottom:11,borderRadius:30}}/></GridItem>
                 {displayCubicleStatus('info',1)}
                 {displayCubicleStatus('info',2)}
                 {displayCubicleStatus('info',3)}
                 {displayCubicleStatus('info',4)}
                 {displayCubicleStatus('info',5)}
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><FemaleToiletIcon style={{height:"auto",width:"71%", backgroundColor:"pink",paddingRight:20, paddingLeft:20, paddingTop:10, paddingBottom:10,borderRadius:30}}/></GridItem>
                    {displayCubicleStatus('info',1)}
                    {displayCubicleStatus('info',2)}
                    {displayCubicleStatus('info',3)}
                    {displayCubicleStatus('info',4)}
        
                </GridContainer>
                <p/>
                <Divider/>
                <p/>
                <GridContainer>
                  <GridItem><AccessibleToiletIcon style={{height:"auto",width:"65%", backgroundColor:"#8AEEBC",paddingRight:15, paddingLeft:15, paddingTop:12, paddingBottom:12,borderRadius:30}}/></GridItem>
                  <div style={{position:"relative", left:-8}}>{displayCubicleStatus('info',1)}</div>
                </GridContainer>

                
                
              </CardBody>
    
          </Card>
        </GridItem>
          
      
    
    </GridContainer>       
    </div>
  );
}
