/*eslint-disable*/
import React from "react";

import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";


const useStyles = makeStyles(styles);

export default function Calendar() {
  const classes = useStyles();

  return (
    <div>
      <Heading
        textAlign="center"
        title="Coming Soon"
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardBody calendar>
            
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
