/*eslint-disable*/
import {React, useState,useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';
import Paginations from "views/Components/Pagination"

//Icon
import BusinessIcon from '@material-ui/icons/Business';

//IAQ Device List
import deviceIAQList from 'DeviceList/deviceIAQList'

//People Chart Compoent
import PeopleCountChart from "views/Charts/PplCountChart"
import IAQSummary from "views/Charts/IAQSummary"
import FloodSummary from "views/Charts/FloodSummary"


import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const useStyles = makeStyles(styles);


export default function Charts() {
  const [currentFloor,setCurrentFloor] = useState("9")
  const [currentToiletType, setCurrentToiletType] = useState("female")

  const handleClick = (floor) =>{
    if (floor == "9" || floor == "8") {setCurrentFloor(floor)}
    //alert("you've clicked floorview "+ floor)
  }
  const handleType = ((type =>{
    setCurrentToiletType(type)
  }))

 //var [dBName, setDBName] = useState(deviceIAQList[0].dbname)
  //const [dBName, setDBName] = useState()

  //console.log("dbIndex",dbIndex)

  
  const classes = useStyles();
  return (
    <div>
      
      <GridContainer>

       
  
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <PeopleCountChart ChartName = "Daily People Counting"/>
        </GridItem>
    
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <IAQSummary ChartName = "Daily IAQ Summary"/>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <FloodSummary ChartName = "Water Leakage Report"/>
        </GridItem>
      
       </GridContainer>
      
    </div>
  );
}
