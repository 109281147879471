/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from 'moment'

//Firebase
import db from 'firebase'
import { doc, collection, query, where, orderBy, limit, getDocs, onSnapshot} from "firebase/firestore";

// react plugin for creating charts
import ChartistGraph from "react-chartist";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
//Icon 
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Divider from '@material-ui/core/Divider';
import Paginations from "views/Components/Pagination"

const useStyles = makeStyles(styles);


export default function Charts(props) {
  
  const dataPoint = 13
  const classes = useStyles();
  const [overallStatus, setOverallStatus] = useState("info")
  const [lastUpdateTime,setLastUpdateTime] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD 23:59'))
  const [queryDate1, setQueryData1] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [queryDate2, setQueryData2] = useState('')
  const [currentFloor,setCurrentFloor] = useState("9")
  const [currentToiletType, setCurrentToiletType] = useState("female")
  const [totalPeopleCount1,setTotalPeopleCount1] = useState("0")
  const [totalPeopleCount2,setTotalPeopleCount2] = useState("0")


  const [chartOptions, setChartOptions] = useState({
    seriesBarDistance: 10,
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function(value) {
        return value.slice(0, 3);
       }
    },
    height: "200px",
  }); 
  
  const [chartData, setChartData] = useState({})


const startOfDayUTC = (queryDate) => { 
  return moment.utc(moment(queryDate).startOf('day').subtract(0, 'days').format()).format()
}

const endOfDayUTC = (queryDate) => {
  return moment.utc(moment(queryDate).endOf('day').subtract(0, 'days').format()).format()
}


//DB Number Pad
const zeroPad = (num, places) => String(num).padStart(places, '0')

const StatusRef = collection(db, "APB_PeopleCount_DailyView");
    

useEffect(() => {
  //console.log("IAQChart effect", props.DBName)
  
  getChartData()
  //const interval = setInterval(() => getChartData(props.DBName, props.SensorMetric), 60000);
 // return () => {/*console.log("clean up ",props.DBName)*/; clearInterval(interval)};
}, [props, queryDate1,queryDate2, currentToiletType, currentFloor]);

const getChartData =  async () => {
    var hourSeries1 = []
    var hourSeries2 = []
    var peopleCountSeries1 = []
    var peopleCountSeries2 = []
    var q1 =  query(StatusRef, where("date","==",moment(queryDate1).format('YYYY-MM-DD')))
    var q2 =  query(StatusRef, where("date","==",moment(queryDate2).format('YYYY-MM-DD')))
    const queryDoc1 = await getDocs(q1);
    const queryDoc2 = await getDocs(q2);
      queryDoc1.forEach((device) => {
          if (device.data().type == currentToiletType && device.data().floor ==currentFloor){
          //console.log("Device Data", device.data(), "type:",device.data().type)
          hourSeries1 = device.data().label
          peopleCountSeries1 = device.data().data
          setTotalPeopleCount1(peopleCountSeries1.reduce((a, b) => a + b, 0))
          }
        })

      queryDoc2.forEach((device) => {
        if (device.data().type == currentToiletType && device.data().floor == currentFloor){
          hourSeries2 = device.data().label
          peopleCountSeries2 = device.data().data
          setTotalPeopleCount2(peopleCountSeries2.reduce((a, b) => a + b, 0))

        }
        })

      setChartData({
          labels: (hourSeries1.length >= hourSeries2.length)?(hourSeries1):(hourSeries2),
          series: [ peopleCountSeries1, peopleCountSeries2]
          });

    return () => unsubscribe()
    
}

  const handleClick = (floor) =>{
    if (floor == "9" || floor == "8") {setCurrentFloor(floor)}
  }

  const handleDateChange = (e) => {
      if (e.target.id=="date1"){setQueryData1(moment(e.target.value).format("YYYY-MM-DD"));}
      if (e.target.id=="date2"){setQueryData2(moment(e.target.value).format("YYYY-MM-DD"));}
  };

  const handleType = ((type =>{
    setCurrentToiletType(type)
  }))

  
  return (
    <div>
          <Card chart>
            <CardHeader color="info" stats icon>
            <CardIcon color="info" >
                <DirectionsWalkIcon/>
              </CardIcon>
              
              <h4 className={classes.cardTitle} style={{position:'relative',top:25,right:20}}>{props.ChartName}</h4>
           
            </CardHeader>
            <CardBody>
            <GridContainer>
              <GridItem> 
                <form className={classes.container} noValidate>
                    <TextField
                      id="date1"
                      label="Date1"
                      type="date"
                      onChange={handleDateChange}
                      defaultValue={queryDate1}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
              </GridItem>
             
              <GridItem style={{position:'relative', top: 25}} >{(queryDate1 != '' && queryDate1 != 'Invalid date')?(<Badge color="info">{queryDate1}</Badge>):(<div></div>)}</GridItem>
             
              <GridItem> 
                <form className={classes.container} noValidate>
                    <TextField
                      id="date2"
                      label="Date2"
                      type="date"
                      onChange={handleDateChange}
                      defaultValue={queryDate2}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
              </GridItem>
              
              <GridItem style={{position:'relative', top: 25}} >{(queryDate2 != '' && queryDate2 != 'Invalid date')?(<Badge color="danger">{queryDate2}</Badge>):(<div></div>)}</GridItem>
            
  

            <GridContainer>
              <GridItem>
                <p></p>
              </GridItem>
  
            </GridContainer>
                      
             
             </GridContainer>
            
            
             <Divider/>

             <ChartistGraph
                className="ct-chart-bar"
                data={chartData}
                type="Bar"
                options={chartOptions}
              />
             
             <GridContainer justify="center">
             <GridItem style={{position:'relative' }} >{(queryDate1 != '' && queryDate1 != 'Invalid date')?(<Badge color="info">Total People Count: {totalPeopleCount1}</Badge>):(<div></div>)}</GridItem>
            <GridItem style={{position:'relative', top: 0}} >{(queryDate2 != '' && queryDate2 != 'Invalid date')?(<Badge color="danger">Total People Count: {totalPeopleCount2}</Badge>):(<div></div>)}</GridItem>
            </GridContainer>
            </CardBody>


            <CardFooter chart>            
              <GridContainer>

                  <GridItem>
                    {(currentToiletType == "female")?(<Button round color="info">Female</Button>):(<Button simple color="info" onClick={()=>handleType("female")}>Female</Button>)}
                    {(currentToiletType == "male")?(<Button round color="info">Male</Button>):(<Button simple color="info" onClick={()=>handleType("male")} >Male</Button>)}
                    {/*(currentToiletType == "accessible")?(<Button round color="info">Accessible</Button>):(<Button simple color="info" onClick={()=>handleType("accessible")} >Accessible</Button>)*/}
                  </GridItem> 
                  <GridItem>
                    <Paginations
                      pages={[
                        { text: 1, active: (currentFloor == "1")?(true):(false)},
                        { text: 2, active: (currentFloor == "2")?(true):(false)},
                        { text: 3, active: (currentFloor == "3")?(true):(false)},
                        { text: 4, active: (currentFloor == "4")?(true):(false)},
                        { text: 5, active: (currentFloor == "5")?(true):(false)},
                        { text: 6, active: (currentFloor == "6")?(true):(false)},
                        { text: 7, active: (currentFloor == "7")?(true):(false)},
                        { text: 8, active: (currentFloor == "8")?(true):(false)},
                        { text: 9, active: (currentFloor == "9")?(true):(false)},
                      ]}
                      color="info"
                      onClick={handleClick}
                    />
                  </GridItem>
              </GridContainer>
              
            </CardFooter>
            <CardFooter>
            <div className={classes.stats}>
                <AccessTime /> Update Every 24 Hours - Last Update {lastUpdateTime} 
              </div>
            </CardFooter>
          </Card>
    </div>
  );
}
