import React from "react";

//Firebase
import { useCallback } from 'react'
import firebase from "firebase";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import ASDlogo from "assets/img/ASD_logo.webp"
import { ContactsOutlined } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [loginErrMsg, setLoginErrMsg] = React.useState("")
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

   //handle login submit
   const handleSubmit = useCallback( e => {
    e.preventDefault()
    const { email, password } = e.target.elements
    
    //console.log("email:" ,email.value, "password",password.value)
    const auth = getAuth()
    
    
      signInWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        
     
      })
      .catch((e) => {
      //alert(e.message)
      setLoginErrMsg("Email or password is incorrect")
    })
  })
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
          
        <GridItem xs={12} sm={6} md={6}>
        <div style={{display:'flex', justifyContent:'center'}} ><img src={ASDlogo} style={{height:60 }}/></div>
          <form onSubmit={handleSubmit}>  
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="login" 
              >
                <h4 className={classes.cardTitle}>ASD Smart Toilet</h4>
            
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              <GridContainer 
                  justify="space-between"
                  alignItems="center"
                  direction="row">
                <GridItem  > 
                  <a href="/auth/register-page"><h6 ></h6></a>
                </GridItem>
                <GridItem> 
                  <a href="/auth/forget-password-page"><h6  >Forget Password</h6></a>
                </GridItem>
              </GridContainer>
               
           
              <div style={{display:'flex', justifyContent:'center'}}>
                <h5 style={{color:'red'}} >{loginErrMsg}</h5>
                </div>
              
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" round style={{backgroundColor:"#b61d22"}}>
                  Login
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
