import React from "react";

//Firebase
import { getAuth, updatePassword , updateProfile, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";



// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import SecurityIcon from '@material-ui/icons/Security';
import avatar from "assets/img/faces/marc.jpg";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

//Style
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
const useStyles = makeStyles(styles);

//Password Checker
import {passwordStrength} from 'variables/checkPassword'

export default function UserProfile() {
  const classes = useStyles();
  const [currentPassword, setcurrentPasword] = React.useState("");
  const [currentPasswordState, setcurrentPasswordState] = React.useState("error");
  const [registerErrMsg, setregisterErrMsg] = React.useState("")
  const [registerEmail, setregisterEmail] = React.useState("");
  const [registerEmailState, setregisterEmailState] = React.useState("");
  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("error");
  const [registerPasswordStrength, setregisterPasswordStrength] = React.useState("");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState("");
  const [registerConfirmPasswordState, setregisterConfirmPasswordState,] = React.useState("error");
  const [registerCheckbox, setregisterCheckbox] = React.useState(false);
  const [registerCheckboxState, setregisterCheckboxState] = React.useState("");
  const [registerPasswordHelper, setregisterPasswordHelper] = React.useState("")

  const [currentUsername ,setCurrentUsername] = React.useState()
  const [currentUserEmail ,setCurrentUserEmail] = React.useState()
  const [newUsername , setNewUsername] = React.useState("")
  const [buttonDisable,setButtonDisable] = React.useState(false)
  const [updateUserText, setUpdateUserText] = React.useState("")
  
  const [passwordInputValue, setpasswordInputValue] = React.useState("")

  const newPassword = "";
  

  React.useEffect(()=> {
    getUserProfile()
    
  },[])

  const getUserProfile =  () =>{
    const auth =  getAuth();
    const user = auth.currentUser;
    (user.displayName)?(setCurrentUsername(user.displayName)):(setCurrentUsername("Please set up your username"))
    setCurrentUserEmail(user.email)
    //console.log("current user name",currentUsername, "Email",currentUserEmail)
  }

  const updateUserProfile = () => {
    setButtonDisable(true)
    setUpdateUserText("Updating Username, Reloading Page")
    const auth = getAuth();
    updateProfile(auth.currentUser, {
      displayName: newUsername, photoURL: null
    }).then(() => {
      window.location.reload();
      // Profile updated!
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }

    // function Password Strength
    const verifyPasswordStrength = (value) => {
      if (passwordStrength(value).id >= 2 && value.length <16  && value.length > 7 ) {
        return true;
      }else {return false}
    };


  
   const updateUserPassword =() => {
        const auth =  getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        //console.log(credential);
        reauthenticateWithCredential(user, credential).then(() => {
          updatePassword(user, registerPassword).then(() => {
            // Update successful.
            //console.log("password updated")
            setregisterErrMsg("Password is updated")
            setregisterPassword("")
            setregisterPasswordState("error")
            setregisterConfirmPassword("")
            setregisterConfirmPasswordState("error")
            setcurrentPasword("")
            setcurrentPasswordState("error")
            setpasswordInputValue("")
        
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            //console.log("error code ",errorCode , "error Message ",errorMessage, )
            setregisterErrMsg(errorMessage)
          });
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          //console.log("error code ",errorCode , "error Message ",errorMessage, )
          setregisterErrMsg(errorMessage)
        });
        
        
      }
    
  const verifyForm = () =>{ 
    if(currentPasswordState == "success" && registerPasswordState == "success"  && registerConfirmPasswordState == "success"){
            return false} 
      else 
            return true
    }
  


  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PermIdentity/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>User Profile</h4>
          </CardHeader>
          <CardBody>
         
              <GridContainer>
                <GridItem xs={3} sm={2} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Username
                  </FormLabel>
                </GridItem>
                <GridItem xs={9} sm={10} md={9}>
                  <CustomInput
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        setNewUsername(event.target.value)
                      },
                      type: "name",
                      placeholder: currentUsername ,
                     
                    }}
                  />
                </GridItem>
              </GridContainer>
              
              <GridContainer>
                <GridItem xs={3} sm={2} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Email
                  </FormLabel>
                </GridItem>
                <GridItem xs={9} sm={10} md={9}>
                  <CustomInput
                    id="email_adress"
                    
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      placeholder: currentUserEmail ,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>


  
              <GridContainer justify="flex-end">
                <GridItem>
                  <span style={{fontSize:12}}>{updateUserText}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                <Button color="rose" 
                  round 
                  className={classes.updateProfileButton}
                  onClick={()=> updateUserProfile()}
                  disabled = {buttonDisable}
                >
                Update Profile
              </Button>
                </GridItem>
              </GridContainer>
          
          </CardBody>
        </Card>
      </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
            <SecurityIcon/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Security</h4>
          </CardHeader>
          <CardBody>

          <GridContainer>
                <GridItem xs={4} sm={3} md={4}> 
                  <FormLabel className={classes.labelHorizontal}>
                    Current Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={9} md={8}>
                <CustomInput

                          success={currentPasswordState === "success"}
                          error={currentPasswordState === "error"}
                          id="currentpassword"
                          
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              
                              if (event.target.value ==""){
                                setcurrentPasswordState("error");
                              }else{
                                setcurrentPasswordState("success");
                              }
                              setcurrentPasword(event.target.value)
                            },
                            type: "password",
                            autoComplete: "off",
                            value: currentPassword,
                           
                            type: "password",
                            autoComplete: "off",
                            placeholder: "Required Field"
                          }}
                    />
                </GridItem>
              </GridContainer>
         
          <GridContainer>
                <GridItem xs={4} sm={3} md={4}> 
                  <FormLabel className={classes.labelHorizontal}>
                    New Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={9} md={8}>
                <CustomInput
                          success={registerPasswordState === "success"}
                          error={registerPasswordState === "error"}
                       
                          id="registerpassword"
                          helperText= {registerPasswordHelper}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              
                              if (verifyPasswordStrength(event.target.value)) {
                                setregisterPasswordState("success");
                                setregisterPasswordHelper("")
                                
                              } else {
                                setregisterPasswordState("error");
                                if (event.target.value.length >15){setregisterPasswordHelper("Password should be less then 16 characters")}
                                else{
                                  setregisterPasswordHelper("8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character")
                                }
                              }
                              setregisterPassword(event.target.value);
                              //console.log("input password:",registerPassword )
                              setregisterPasswordStrength(passwordStrength(event.target.value).value)
                            },
                            type: "password",
                            autoComplete: "off",
                            value: registerPassword,
                            endAdornment: (

                              <InputAdornment
                                position="end"
                                className={classes.inputAdornment}
                              >
                                {({registerPasswordStrength} == "Weak" ||{registerPasswordStrength} == "Too weak" )
                                    ?(<span  Color="red" style={{fontSize:10, fontWeight: "bold", position:'relative',top:5,right:10}}>{registerPasswordStrength}</span>)
                                    :(<span style={{fontSize:10, position:'relative',top:5,right:10}}>{registerPasswordStrength}</span>)
                                }
                    
                              </InputAdornment>
                            ),
                            type: "password",
                            autoComplete: "off",
                            placeholder: "Required Field",
                            value: registerPassword
                          }}
                    />
                </GridItem>
              </GridContainer>
              
              <GridContainer>
                <GridItem xs={4} sm={3} md={4}> 
                  <FormLabel className={classes.labelHorizontal}>
                    Confirm Password 
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={9} md={8}>
                <CustomInput
                        success={registerConfirmPasswordState === "success"}
                        error={registerConfirmPasswordState === "error"}
                        
                        id="registerconfirmpassword"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            if (event.target.value ==""){
                              setregisterConfirmPasswordState("error");
                            }
                            if (registerPassword === event.target.value) {
                              setregisterConfirmPasswordState("success");
                            } else {
                              setregisterConfirmPasswordState("error");
                            }
                            setregisterConfirmPassword(event.target.value);
                          },
                        
                          type: "password",
                          autoComplete: "off",
                          placeholder: "Required Field",
                          value: registerConfirmPassword
                        }}
                      />
                     
                      <div className={classes.center}>
                      <h6 style={{color:"red"}}>{registerErrMsg}</h6>
                    </div>
                </GridItem>
              </GridContainer>


  
              <GridContainer justify="flex-end">
                <GridItem xs={12} sm={12} md={9}>
                <Button color="rose" 
                  round 
                  className={classes.updateProfileButton}
                  onClick={()=>{updateUserPassword()}}
                  disabled = {verifyForm()}
                >
                Change Password
              </Button>
                </GridItem>
              </GridContainer>
          
          </CardBody>
        </Card>
      </GridItem>
      </GridContainer>




           

    </div>
  );
}
