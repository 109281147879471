import { useState, useEffect, useContext, createContext } from 'react'
// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth' //@firebase/auth before
import { initializeApp } from "firebase/app"; 
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7r4b_Vx7SxB1VloJbpHp5E2dpxzMhaqk",
  authDomain: "axolo-apb.firebaseapp.com",
  projectId: "axolo-apb",
  storageBucket: "axolo-apb.appspot.com",
  messagingSenderId: "123985274865",
  appId: "1:123985274865:web:d989f61c2b166a71f4c650",
  measurementId: "G-4V21H2V725"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore();
//const analytics = getAnalytics(app);

export const storage = getStorage();

//AuthContext
export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(),setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}

export default  db

