/*eslint-disable*/
import {React, useState, useEffect} from "react";

//FireBase
import db from 'firebase'
import {storage} from 'firebase'
import {ref, getDownloadURL ,uploadBytesResumable,  listAll,deleteObject, connectStorageEmulator } from "firebase/storage";
import { doc, updateDoc, setDoc, onSnapshot , getDoc, getDocs, query, collection} from "firebase/firestore";

// Material UI  components
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input'
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter"
import Table from "components/Table/Table.js";


//Icon
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ImageIcon from '@material-ui/icons/Image';
import SubjectIcon from '@material-ui/icons/Subject';
import TvIcon from '@material-ui/icons/Tv';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'
import {BsFillCircleFill} from "react-icons/bs"
import DirectionsIcon from '@material-ui/icons/Directions';

// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

//Video
import ReactPlayer from 'react-player/lazy'

const videoRef = doc(db, 'APB_SmartInfomation', 'Video');
const imageRef = doc(db, 'APB_SmartInfomation', 'Image');
const textRef = doc(db, 'APB_SmartInfomation', 'Text');
const posterRef = doc(db, 'APB_PosterDisplay', 'Poster');
const listRef = ref(storage, 'video');
const displayQuery = query(collection(db, "APB_SmartDisplay_Status")) 
const directoryRef =  doc(db, 'APB_MainDirectory','Text') ;

const useStyles = makeStyles(styles);

export default function Calendar() {
  const classes = useStyles();
 //UseState
  const [videoFileList, setVideoFileList] = useState([])
  const [imageFileList, setImageFileList] = useState([])
  const [posterFileList, setPosterFileList] = useState([])
  const [progress, setProgress] = useState(0);
  const [prefetchProgress, setPrefetchProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const [posterProgress, setPosterProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState("")
  const [inputContent,setInputContent] = useState([])
  const [currentContent,setCurrentContent] = useState([])
  const [currentVideoFilename, setCurrentVideoFilename] = useState()
  const [currentVideoUrl, setCurrentVideoUrl] = useState()
  const [currentImageFilename, setCurrentImageFilename] = useState()
  const [currentImageUrl, setCurrentImageUrl] = useState([])
  const [currentPosterFilename, setCurrentPosterFilename] = useState()
  const [currentPosterUrl, setCurrentPosterUrl] = useState([])
  const [download, setDownload] = useState("no")
  const [displayStatus, setDisplayStatus] = useState([])

  const [MAINBLKZh, setMAINBLKZh] = useState([])
  const [MAINBLKEn, setMAINBLKEn] = useState([])
  const [MAINBLKTable, setMAINBLKTable] = useState([])

  const [BOLDBLKZh, setBOLDBLKZh] = useState([])
  const [BOLDBLKEn, setBOLDBLKEn] = useState([])
  const [BOLDBLKTable, setBOLDLKTable] = useState([])


  useEffect(() => {
   
    getVideoFileList()
    getImageFileList()
    getPosterFileList()
    getCurrentContent()
    getCurrentImage()
    getCurrentPoster()
    getCurrentMainDirectory()
   
  }, []);

   useEffect(() =>{
   
    getDisplayStatus()
    const interval = setInterval(() => getDisplayStatus(), 15*60*1000);
    return () => {clearInterval(interval)};
   },[])

   const getCurrentMainDirectory = async () => {
    getDoc(directoryRef)
    .then( docSnap => { console.log("getCurrentMainDirectory", docSnap.data().MAINBLKZh,docSnap.data().MAINBLKEn)
        renderTable(docSnap.data().MAINBLKZh,docSnap.data().MAINBLKEn,docSnap.data().BOLDBLKZh,docSnap.data().BOLDBLKEn)
      });
    
   }

  const handleDirectoryChange = (event, lang , blk, MAINBLKZh, MAINBLKEn,BOLDBLKZh, BOLDBLKEn) =>{
    var tempMAINBLKZh = MAINBLKZh
    var tempMAINBLKEn = MAINBLKEn
    var tempBOLDBLKZh = BOLDBLKZh
    var tempBOLDBLKEn = BOLDBLKEn

    if (lang =="Zh") {
     if (blk == "MAIN") {
      tempMAINBLKZh[event.target.id] = event.target.value
      console.log(tempMAINBLKZh, event.target.id)
      }
     else {
      tempBOLDBLKZh[event.target.id] = event.target.value
     }
    }

    if (lang =="En") {
      if (blk == "MAIN"){
      tempMAINBLKEn[event.target.id] = event.target.value
      console.log(tempMAINBLKEn, event.target.id)
      }
      else{
        tempBOLDBLKEn[event.target.id] = event.target.value
      }
     }
     setMAINBLKZh(tempMAINBLKZh)
     setMAINBLKEn(tempMAINBLKEn)
     setBOLDBLKZh(tempBOLDBLKZh)
     setBOLDBLKEn(tempBOLDBLKEn)

  }
  const updateDirectory = async () => {
    
    console.log("click",MAINBLKZh,MAINBLKEn)
    await updateDoc(directoryRef,{"MAINBLKZh":MAINBLKZh, "MAINBLKEn":MAINBLKEn, BOLDBLKZh:BOLDBLKZh, "BOLDBLKEn":BOLDBLKEn})
  }

  const renderTable = (InputMAINBLKZh,InputMAINBLKEn,InputBOLDBLKZh,InputBOLDBLKEn) => {
        //setMAINBLKZh(InputMAINBLKZh)
        //setMAINBLKEn(InputMAINBLKEn)
        console.log(InputBOLDBLKZh)
        var tempMAINTable = []
        
            for (var i=0; i<=9 ; i++) {

             tempMAINTable.push(
             
            [ "MAIN",
                        (9-i != 0)?((9-i)+"/F"):("G/F"),
                        <TextField
                          id={i}
                          label="Chinese"
                          multiline
                          defaultValue={InputMAINBLKZh[i]}
                          onChange={event => handleDirectoryChange(event,"Zh","MAIN", InputMAINBLKZh,InputMAINBLKEn,InputBOLDBLKZh,InputBOLDBLKEn)}
                          variant="outlined"
                          fullWidth
                          inputProps={{style: {fontSize:10}}}
                          InputLabelProps={{style: {fontSize: 16}}}
                        />,
                        <TextField
                        id={i}
                        label="English"
                        multiline
                        defaultValue={InputMAINBLKEn[i]}
                        onChange={event => handleDirectoryChange(event,"En","MAIN", InputMAINBLKZh,InputMAINBLKEn,InputBOLDBLKZh,InputBOLDBLKEn)}
                        variant="outlined"
                        fullWidth
                        inputProps={{style: {fontSize:10}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                      />,          
              ],
             
             )
 
            }
            setMAINBLKTable(tempMAINTable)

            var tempBOLDTable = []
        
            for (var k=0; k<=9 ; k++) {

             tempBOLDTable.push(
             
             [ "BOLD",
                        (9-k != 0)?((9-k)+"/F"):("G/F"),
                        <TextField
                          id={k}
                          label="Chinese"
                          multiline
                          defaultValue={InputBOLDBLKZh[k]}
                          onChange={event => handleDirectoryChange(event,"Zh","BLOD", InputMAINBLKZh,InputMAINBLKEn,InputBOLDBLKZh,InputBOLDBLKEn,)}
                          variant="outlined"
                          fullWidth
                          inputProps={{style: {fontSize:10}}}
                          InputLabelProps={{style: {fontSize: 16}}}
                        />,
                        <TextField
                        id={k}
                        label="English"
                        multiline
                        defaultValue={InputBOLDBLKEn[k]}
                        onChange={event => handleDirectoryChange(event,"En", "BOLD",InputMAINBLKZh,InputMAINBLKEn,InputBOLDBLKZh,InputBOLDBLKEn)}
                        variant="outlined"
                        fullWidth
                        inputProps={{style: {fontSize:10}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                      />,          
              ],
             
             )
 
            }
            setBOLDLKTable(tempBOLDTable)
            
    //return nameList.split(',').join("\n");
  }

  const formatList = (nameList) =>{
    let regex = /[\r\n\x0B\x0C\u0085\u2028\u2029]+/g
    return  nameList.replace(regex, ",");
     
  }
  


  const getDisplayStatus = async () =>{
    setDisplayStatus([])
    const querySnapshot = await getDocs(displayQuery);
    querySnapshot.forEach((doc) =>{
      
      console.log(doc.id.replace("_Heartbeat", " "), doc.data().hkdatetime, doc.data().status)
      var tempItem = [
        renderToiletStatusSwitch(doc.data().status),
        doc.id.replace("_Heartbeat", " "),
        doc.data().hkdatetime
      ]
      setDisplayStatus(arr => [...arr, tempItem])
    })
  }

  const renderToiletStatusSwitch = (status) => {
    switch (status) {
          case "success":
            return <BsFillCircleFill style={{ color:"green" , fontSize: "20" }} />;
          case "warning":
            return <BsFillCircleFill style={{ color:"orange" , fontSize: "20" }} />;
          case "danger":
            return <BsFillCircleFill style={{ color:"red" , fontSize: "20" }} />;
          case "login":
            return <BsFillCircleFill style={{ color:"gray" , fontSize: "20" }}/>
          default:
            return <BsFillCircleFill style={{ color:"gray" , fontSize: "20" }}/>
    }
  }
  const getVideoFileList = () => {
    setVideoFileList([])
    listAll(listRef)
    .then((res) => {
      /*
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
        //console.log("List Folder: ", folderRef)
      });
      */
      
      res.items.forEach((itemRef) => {
        // All the items under listRef.
          var tempItem = [
            itemRef.name,
            <IconButton onClick={() => deleteVideoFile(itemRef.name)}> <DeleteIcon /></IconButton>,
            <IconButton onClick={() => publishVideo(itemRef.name, 1)}> <PublishIcon /></IconButton>,
            <IconButton onClick={() => publishVideo(itemRef.name, 2)}> <PublishIcon /></IconButton>,
            <IconButton onClick={() => publishVideo(itemRef.name, 3)}> <PublishIcon /></IconButton>

            ]
        setVideoFileList(arr => [...arr, tempItem]);
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error)
    });
  }

  const getPosterFileList = () => {
    setPosterFileList([])
    const listPosterRef = ref(storage, 'poster');
    listAll(listPosterRef)
    .then((res) => {
      /*
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
        console.log("List Folder: ", folderRef)
      });
      */
      res.items.forEach((itemRef ,index) => {
        // All the items under listRef.
        //console.log("list Image Item: ",getDownloadURL(ref(storage, 'image/'+itemRef.name)))
        var tempItem = [
      
          itemRef.name,
          <IconButton onClick={() => deletePosterFile(itemRef.name)}> <DeleteIcon /></IconButton>,
          <IconButton onClick={() => publishPoster(itemRef.name, 1)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishPoster(itemRef.name, 2)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishPoster(itemRef.name, 3)}> <PublishIcon /></IconButton>,
          ]
        setPosterFileList(arr => [...arr, tempItem]);
      });
    }).catch((error) => {
      console.log(error)
      // Uh-oh, an error occurred!
    });
  }

  const getImageFileList = () => {
    setImageFileList([])
    const listImageRef = ref(storage, 'image');
    listAll(listImageRef)
    .then((res) => {
      /*
      res.prefixes.forEach((folderRef) => {
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
        console.log("List Folder: ", folderRef)
      });
      */
      res.items.forEach((itemRef ,index) => {
        // All the items under listRef.
        //console.log("list Image Item: ",getDownloadURL(ref(storage, 'image/'+itemRef.name)))
        var tempItem = [
      
          itemRef.name,
          <IconButton onClick={() => deleteImageFile(itemRef.name)}> <DeleteIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 1)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 2)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 3)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 4)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 5)}> <PublishIcon /></IconButton>,
          <IconButton onClick={() => publishImage(itemRef.name, 6)}> <PublishIcon /></IconButton>
          ]
        setImageFileList(arr => [...arr, tempItem]);
      });
    }).catch((error) => {
      console.log(error)
      // Uh-oh, an error occurred!
    });
}
  

  const uploadFile = (e) => {
  const file = e.target.files[0];
  const storageRef = ref(storage, 'video/' + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file);
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          setProgress(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          } 
        }, 
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          console.log(error)
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            // ...
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        }, 
        () => {
          // Upload completed successfully, now we can get the download URL
          /*
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
          });
          */        
          setVideoFileList([])
          getVideoFileList()
        }
      );
          /*uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            setFileList([])
            getFile()
          });*/  
  }
  
  const uploadImageFile = (e) => {
   
    const file = e.target.files[0];
    if (!imageFileList.includes(file.name)){
      const storageRef = ref(storage, 'image/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      setImageProgress(progress)
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
        default:
          break;
      } 
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
        default:
          break;
    
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
      setImageFileList([])
      getImageFileList()
      
    }
  );
    }else{
      console.log("File Existed")
      alert("File Existed")
    }
  
  }
  
  const uploadPosterFile = (e) => {
   
    const file = e.target.files[0];
    if (!imageFileList.includes(file.name)){
      const storageRef = ref(storage, 'poster/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      setPosterProgress(progress)
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
        default:
          break;
      } 
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
        default:
          break;
    
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
      setPosterFileList([])
      getPosterFileList()
      
    }
  );
    }else{
      console.log("File Existed")
      alert("File Existed")
    }
  
  }
  

  const deleteVideoFile = (filename) =>{
    console.log("delete file", filename)
    const desertRef = ref(storage, 'video/' + filename);
    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log("file ",filename," is deleted ")
      setVideoFileList([])
      getVideoFileList()
      console.log(videoFileList)
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }
  
  const deleteImageFile = (filename) =>{
    console.log("delete Imagefile", filename)
    const desertRef = ref(storage, 'image/' + filename);
    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log("file ",filename," is deleted ")
      setImageFileList([])
      getImageFileList()
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  const deletePosterFile = (filename) =>{
    console.log("delete Posterfile", filename)
    const desertRef = ref(storage, 'poster/' + filename);
    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log("file ",filename," is deleted ")
      setPosterFileList([])
      getPosterFileList()
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }
  
  // Get the download URL
  /*
  const getVideoUrl =  (filename) =>{
    const videoRef = ref(storage, 'video/'+filename);
    getDownloadURL(videoRef)
    .then((url) => {
      setVideoUrl(url)
     //return url
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
        // ...
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
        default:
          break;
      }
    });
  }
  */
  const publishVideo = (filename, index) =>{
    const currentVideoRef = ref(storage, 'video/'+filename); 
    getDownloadURL(currentVideoRef)
    .then((url) => {
      console.log("Set current file name and URL")
      const currentfilename = "currentfilename" + index 
      const currentUrl = "currentUrl"+index
      updateDoc(videoRef,{[currentfilename] :filename, [currentUrl]: url})
      setCurrentVideoUrl(url)
      setDownload("no")
    })
  }
  
  const publishImage = (filename, index) =>{
    const currentImageRef = ref(storage, 'image/'+filename);
    getDownloadURL(currentImageRef)
    .then((url) => {
      console.log("Set current file name and URL")
      const currentfilename = "currentfilename" + index 
      const currentUrl = "currentUrl"+index
      updateDoc(imageRef,{[currentfilename] : filename, [currentUrl]  : url})
    })
  }

  const publishPoster = (filename, index) =>{
    const currentPosterRef = ref(storage, 'poster/'+filename);
    getDownloadURL(currentPosterRef)
    .then((url) => {
      console.log("Set current file name and URL")
      const currentfilename = "currentfilename" + index 
      const currentUrl = "url"+index
      updateDoc(posterRef,{[currentfilename] : filename, [currentUrl]  : url})
    })
  }
  /*
  const getCurrentVideo =  async () =>{
    const unsub = await onSnapshot(doc(db,'APB_SmartInfomation', 'Video'), (doc) => {
      //console.log("Current Video: ", doc.data().currentfilename);
      setCurrentVideoFilename(doc.data().currentfilename)
      setCurrentVideoUrl(doc.data().currentUrl)
      console.log("Current Video URL: ", doc.data().currentUrl);
      //console.log("currentVideoFilename",setCurrentVideoFilename)
      //prefetch_file(doc.data().currentUrl, onSuccess, onProgress, onError)
      //setVideoUrl(doc.data().currentUrl)
      // Get the download URL
      //getVideoUrl(doc.data().currentfilename)
    });
  }
  */

  const getCurrentImage =  async () =>{
    const unsub = await onSnapshot(doc(db,'APB_SmartInfomation', 'Image'), (doc) => {
      //console.log("Current Video: ", doc.data().currentfilename);
      //setCurrentVideoFilename(doc.data().currentfilename)
      //setCurrentVideoUrl(doc.data().currentUrl)
      console.log("Current Image Filename 1: ", doc.data().currentfilename1)
      console.log("Current image URL: ", doc.data().currentUrl1);
      setCurrentImageUrl([doc.data().currentUrl1,doc.data().currentUrl2,doc.data().currentUrl3,doc.data().currentUrl4,doc.data().currentUrl5,doc.data().currentUrl6])
      setCurrentImageFilename([doc.data().currentfilename1,doc.data().currentfilename2])

      //console.log("currentVideoFilename",setCurrentVideoFilename)
      //prefetch_file(doc.data().currentUrl, onSuccess, onProgress, onError)
      //setVideoUrl(doc.data().currentUrl)
      // Get the download URL
      //getVideoUrl(doc.data().currentfilename)
    });
  }

  const getCurrentPoster =  async () =>{
    const unsub = await onSnapshot(doc(db,'APB_PosterDisplay', 'Poster'), (doc) => {
      //console.log("Current Video: ", doc.data().currentfilename);
      //setCurrentVideoFilename(doc.data().currentfilename)
      //setCurrentVideoUrl(doc.data().currentUrl)
      console.log("Current Poster Filename 1: ", doc.data().currentfilename1)
      console.log("Current Poster URL 1: ", doc.data().url1);
      setCurrentPosterUrl([doc.data().url1,doc.data().url2,doc.data().url3])
      setCurrentPosterFilename([doc.data().currentfilename1,doc.data().currentfilename2,doc.data().currentfilename3])

      //console.log("currentVideoFilename",setCurrentVideoFilename)
      //prefetch_file(doc.data().currentUrl, onSuccess, onProgress, onError)
      //setVideoUrl(doc.data().currentUrl)
      // Get the download URL
      //getVideoUrl(doc.data().currentfilename)
    });
  }

  const getCurrentContent = () =>{
    const unsub = onSnapshot(doc(db,'APB_SmartInfomation', 'Text'), (doc) => {
      console.log("Current Context: ", doc.data().content);
      setCurrentContent(doc.data().content)
      setInputContent(doc.data().content)
    })
  }
  
  const publishContent = index => e =>{
    console.log(inputContent)
    console.log("publish")
    updateDoc(textRef,{"content":inputContent})
  }
  
  const onTextChange = index => e =>{
    //console.log('index: ' + index);
    //console.log('property value: '+ e.target.value);
    let newArr = [...inputContent];
    newArr[index] = e.target.value; 
    setInputContent(newArr)
    //console.log(content)
    
  }


  const onSuccess = (url) => {
    console.log(url);
    setVideoUrl(url)
  }
  const onProgress = (pc) => {
    //setPrefetchProgress(pc)
  }
  
  const onError = () => {
  
  }
  
  const prefetch_file = (url, fetched_callback, progress_callback, error_callback) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
  
        xhr.addEventListener("load", function () {
        if (xhr.status === 200) {
        var URL = window.URL || window.webkitURL;
        var blob_url = URL.createObjectURL(xhr.response);
        fetched_callback(blob_url);
        } else {
        error_callback();
        }
        }, false);
  
        var prev_pc = 0;
        xhr.addEventListener("progress", function (event) {
        if (event.lengthComputable) {
        var pc = Math.round((event.loaded / event.total) * 100);
        //console.log("progress: ",pc)
        setPrefetchProgress(pc)
        if (pc !== prev_pc) {
        prev_pc = pc;
        progress_callback(pc);
        }
        }
        });
        xhr.send();
  }  

  const loadVideo = () =>{
    setDownload("yes")
    prefetch_file(currentVideoUrl, onSuccess, onProgress, onError)
  }


  return (
    <GridContainer >
      <GridItem xs={12} sm={12} md={12}>
          <Card style={{textAlign: "center"}} >
            <CardHeader color="info" stats icon>
              <CardIcon color="info"><TvIcon/></CardIcon>
              <h4 className={classes.cardIconTitle}>Main Video</h4>
            </CardHeader>
            <CardBody>
              <Table
                  tableHead = {["Status","Display","Last Update"]}
                  tableData = {displayStatus}
                />
            </CardBody>
            <CardFooter product> </CardFooter>
            <CardFooter product>
           
            </CardFooter>
          </Card>  
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign: "center"}} >
                <CardHeader color="info" stats icon>
                  <CardIcon color="info"><DirectionsIcon/></CardIcon>
                  <h4 className={classes.cardIconTitle}>Main Directory</h4>
                </CardHeader>
                <CardBody>
                  <Table 
                    striped
                    tableHead={["Block","Floor","Name"]}
                    tableData={ MAINBLKTable }>
                  </Table>
                  <Table 
                    striped
                    
                    tableData={ BOLDBLKTable }>
                  </Table>
               
                  <CardFooter product>  
                    <Button
                        variant="contained" 
                        color="primary"
                        onClick={updateDirectory}>
                          Update
                    </Button>
                  </CardFooter>
                    
               
                     
                </CardBody>
             

          
            </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign: "center"}} >
                <CardHeader color="info" stats icon>
                  <CardIcon color="info"><PhotoAlbumIcon/></CardIcon>
                  <h4 className={classes.cardIconTitle}>Poster</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <Card>Poster 1<CardMedia  component="img" image={currentPosterUrl[0]}/></Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <Card >Poster 2<CardMedia component="img" image={currentPosterUrl[1]}/></Card>
                      </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <Card>Poster 3<CardMedia  component="img" image={currentPosterUrl[2]}/></Card>
                    </GridItem>          
                  </GridContainer>
  
                </CardBody>
             
                <CardBody>
                  <Table
                    tableHead = {["File Name" , "Delete", "Poster 1","Poster 2","Poster 3"]}
                    tableData = {posterFileList}
                  />
                </CardBody>
            <CardFooter product>
              {(posterFileList.length < 3)?(<input type="file" accept="image/*" onChange={uploadPosterFile}/>):(<div><small>Max. Number of File is 3 </small></div>)}
              
            </CardFooter>
            </Card>
        </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
          <Card style={{textAlign: "center"}} >
            <CardHeader color="info" stats icon>
              <CardIcon color="info"><VideoLibraryIcon/></CardIcon>
              <h4 className={classes.cardIconTitle}>Main Video</h4>
            </CardHeader>
            {/*(download == "no")?(
            <CardBody >
              <IconButton  onClick={() => loadVideo(currentVideoUrl)}> <CloudDownloadIcon /></IconButton>
              <br></br>
              <small>Click the icon to load video</small>
              <br></br>
              <small >Current Video is {currentVideoFilename}</small>            
            </CardBody>
            ):(
            <CardBody>
            {(prefetchProgress < 100)?
              (
                <div> 
                  <h4>Loading Video {prefetchProgress}%</h4>
                  <LinearProgress variant="determinate" value={prefetchProgress}/>   
                </div>
              )
            :(
              <div style={{width:'auto',height:'auto'}}>
              <ReactPlayer width='100%' height='100%' controls={true} url={videoUrl} loop={false}/>
              </div>
              )}
            </CardBody>
            )*/ }
            <CardBody>
              <Table
                  tableHead = {["File Name" , "Delete", "Project 1","Project 2","Project 3"]}
                  tableData = {videoFileList}
                />
            </CardBody>
            <CardFooter product> </CardFooter>
            <CardFooter product>
              {(videoFileList.length < 3)?(<input type="file" accept="video/*" onChange={uploadFile}/>):(<div><small>Max. Number of File is 3 </small></div>)}
              {(progress > 0 && progress < 100 )?(<CardBody> <LinearProgress variant="determinate" value={progress} /> {Math.round(progress)}%</CardBody>):(<div></div>)}
            </CardFooter>
          </Card>  
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign: "center"}} >
                <CardHeader color="info" stats icon>
                  <CardIcon color="info"><ImageIcon/></CardIcon>
                  <h4 className={classes.cardIconTitle}>Image</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>Project 1A<CardMedia  component="img" image={currentImageUrl[0]}/></Card>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card >Project 1B<CardMedia component="img" image={currentImageUrl[1]}/></Card>
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>Project 2A<CardMedia  component="img" image={currentImageUrl[2]}/></Card>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card >Project 2B<CardMedia component="img" image={currentImageUrl[3]}/></Card>
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>Project 3A<CardMedia  component="img" image={currentImageUrl[4]}/></Card>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card >Project 3B<CardMedia component="img" image={currentImageUrl[5]}/></Card>
                      </GridItem>
                  </GridContainer>
               
                     
                </CardBody>
             
                <CardBody>
                  <Table
                    tableHead = {["File Name" , "Delete", "Project 1A", "Project 1B","Project 2A", "Project 2B","Project 3A", "Project 3B"]}
                    tableData = {imageFileList}
                  />
                </CardBody>
            <CardFooter product>
              {(imageFileList.length < 6)?(<input type="file" accept="image/*" onChange={uploadImageFile}/>):(<div><small>Max. Number of File is 6 </small></div>)}
              
            </CardFooter>
            </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign: "center"}} >
                <CardHeader color="info" stats icon>
                  <CardIcon color="info"><SubjectIcon/></CardIcon>
                  <h4 className={classes.cardIconTitle}>Text</h4>
                </CardHeader>
                <CardBody>
                
                    <TextField
                        id="outlined-multiline-static"
                        label="Project 1"
                        multiline
                        minRows={1}
                        maxRows={1}
                        defaultValue={currentContent[0]}
                        onChange={onTextChange(0)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth={true}
                        
                      />
        
                      <p></p><br></br><p></p>
                 
                 
                    <TextField
                        id="outlined-multiline-static"
                        label="Project 2"
                        multiline
                        minRows={1}
                        maxRows={1}
                        defaultValue={currentContent[1]}
                        onChange={onTextChange(1)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth={true}
                        
                      />

                    <p></p><br></br><p></p>
                    <TextField
                        id="outlined-multiline-static"
                        label="Project 3"
                        multiline
                        minRows={1}
                        maxRows={1}
                        defaultValue={currentContent[2]}
                        onChange={onTextChange(2)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth={true}
                        
                      />


                    <p></p><br></br><p></p>
             
                  <CardFooter product>  
                    <Button
                        variant="contained" 
                        color="primary"
                        onClick={publishContent()}>
                          Update
                    </Button>
                  </CardFooter>
                    
               
                     
                </CardBody>
             

          
            </Card>
        </GridItem>


    </GridContainer>
    
  );
}
