import React, { useEffect, useState, component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'

//Firebase
import db from 'firebase'
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";

//Icon
import CancelIcon from "@material-ui/icons/Cancel";

//Default Components
import {Paper} from "@material-ui/core";

//Custom Components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Divider from '@material-ui/core/Divider';

////Render
import {renderSensorBattery,renderToiletTypeSwitch,renderToiletStatusSwitch,renderToiletDate,renderHeader} from "views/Modal/RenderTableItem"

//Style 
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);


export default  function FemaleToiletModal(props)  {
  //const [accessToken, setAccessToken] = useState("");

  const classes = useStyles();
  const [urinalTableList, setUrinalTableList] = useState([])
  const [cubicleTableList, setCubicleTableList] = useState([])
  const [error, setError] = useState();
  const [overallStatus, setOverallStatus] = useState("danger")
  const UrinalStatusRef = collection(db, "APB_Urinal_Status");
  const CubicleStatusRef = collection(db, "APB_Cubicle_Status");
  

useEffect(() => {
  fetchCubicleRefData()
  const interval = setInterval(() => fetchCubicleRefData(), 60000);
  return () => {clearInterval(interval)};

}, [props])

  useEffect(() => {
    fetchStatus()
    const interval = setInterval(() =>   fetchStatus(), 60000);
    return () => {clearInterval(interval)};
  }, [props])


const fetchCubicleRefData = async () => {
    var toiletList = [];
    var tempList = [];
    var status = ""
    const q =  query(CubicleStatusRef)
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        //console.log("data" , doc.data())
        
        if (doc.data().block == props.Block && doc.data().floor == props.Floor && doc.data().type=="female") {
          if (doc.data().status == "login") {status="login"}else {status="success"}
          tempList.push([
              
            doc.data().type,
            renderToiletStatusSwitch(doc.data().status),
            renderSensorBattery(doc.data().battery),
            renderToiletTypeSwitch(doc.data().type),
            doc.data().cubicleId,
            doc.data().occupancy,
            (doc.data().usagetime !=0 )?(<span style={{fontSize:12,marginLeft:-5,color:(doc.data().usagetime >= 900)?('red'):('black')}}>{moment.duration(doc.data().usagetime,'seconds').format('hh:mm:ss')}</span>):(""),
            renderToiletDate(moment(doc.data().hkdatetime).format('YYYY-MM-DD / HH:mm'))
          ])
         
          //setLastUpdate(moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm'))
         }
         //console.log("tableList",tableList)

  })
  tempList.sort();
  toiletList = tempList.map(item => item.splice(0,1))
  setCubicleTableList(tempList)

}

const fetchStatus = async () => {
    
  var tempCubicleStatus = [];
  const cubicle =  query(CubicleStatusRef)
  const querySnapshotCubicle = await getDocs(cubicle);
    //console.log("querySnapshot ",querySnapshot.size)
    querySnapshotCubicle.forEach((doc) => {
        //console.log("data" , doc.data())
        if (doc.data().floor == props.Floor && doc.data().type=="female") {
          tempCubicleStatus.push(doc.data().status)
          //setLastUpdate(moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm'))
         }

  })
  if (tempCubicleStatus.includes("login")  ) {
    setOverallStatus("danger");
  }else {

      setOverallStatus("success");
    }
}


  return (

      <Paper style={{ width: "100%", height: "auto" , overflow: "auto",  backgroundColor:"#767676" }}>
        <Card>
          <CardHeader color={overallStatus}>
            <CancelIcon
              style={{
                fontSize: 24,
                position: "absolute",
                top: "30%",
                right: "3%",
                opacity: 0.5,
              }}
              onClick={() => props.ModalHandleClose()}
            />
            <h4 className={classes.cardTitleWhite}>Female Toilet</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
            <CardBody>
              <h3 style={{fontWeight:"bold", }}>Cubicle</h3>
                <Table
                  tableHead = {[renderHeader("Vancy") , renderHeader("Power") ,renderHeader("Type"), renderHeader("ID"), renderHeader("Usage"), renderHeader("Timer"),renderHeader("Last Update")]}
                  tableData = {cubicleTableList}
                />

            </CardBody>
        </Card>
      </Paper>
   
  );
}
