import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'

//Firebase
import db from 'firebase'
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";

//Icon
import CancelIcon from "@material-ui/icons/Cancel";

//Default Components
import {Paper} from "@material-ui/core";

//Custom Components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

////Render
import {renderSensorBattery,renderToiletTypeSwitch,renderToiletStatusSwitch,renderToiletDate} from "views/Modal/RenderTableItem"

//Style
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default  function TrashLevellModal(props)  {
  //const [accessToken, setAccessToken] = useState("");

  const classes = useStyles();
  const [tableList, setTableList] = useState([])
  const [error, setError] = useState();
  const [overallStatus, setOverallStatus] = useState("danger")
  const StatusRef = collection(db, props.DBname);

  useEffect(() => {
    fetchDataOverStatus()
        const interval = setInterval(() => fetchDataOverStatus(), 60000);
    return () => {clearInterval(interval)};
  }, [props])

  useEffect(() => { 
    fetchDataList();
    const interval = setInterval(() =>  fetchDataList(), 60000);
    return () => {clearInterval(interval)};
  }, [props])

  const fetchDataList =  async () =>  {
    
      var toiletList = [];
      var tempList = [];
      const q =  query(StatusRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
          if (doc.data().block == props.Block && doc.data().floor == props.Floor) {
            //console.log(props.Title," Type:",doc.data().type, "ID", doc.data().cubicleId, "time",moment(doc.data().hkdatetime).format('YYYY-MM-DD / HH:mm'))
            tempList.push([
                doc.data().type,
                renderToiletStatusSwitch(doc.data().status),
                renderSensorBattery(doc.data().battery),
                renderToiletTypeSwitch(doc.data().type),
                doc.data().cubicleId,
                renderToiletDate(moment(doc.data().hkdatetime).format('YYYY-MM-DD / HH:mm'))
            ])

           }
    })
    tempList.sort();
    toiletList = tempList.map(item => item.splice(0,1))
    setTableList(tempList)
  }

  const fetchDataOverStatus = async () =>  {
    var tempStatus = [];
    const q =  query(StatusRef)
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

          if (doc.data().block == props.Block && doc.data().floor == props.Floor) {
            tempStatus.push(doc.data().status)
           }
      if (tempStatus.includes("danger") ||tempStatus.includes("login")  ) {
        setOverallStatus("danger");
      }else {
        if (tempStatus.includes("warning")){
          setOverallStatus("warning");
        }else {
          setOverallStatus("success");
        }
      }
    })
  }

  return (

      <Paper style={{ width: "100%", height: "100%" , overflow: "auto", backgroundColor:"#767676", }}>
        <Card>
          <CardHeader color={overallStatus}>
            <CancelIcon
              style={{
                fontSize: 24,
                position: "absolute",
                top: "30%",
                right: "3%",
                opacity: 0.5,
              }}
              onClick={() => props.ModalHandleClose()}
            />
            <h4 className={classes.cardTitleWhite}>{props.Title}</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
            <CardBody>
                <Table
                    tableHead = {["Status" , "Batt", "Type", "ID", "Last Update"]}
                    tableData = {tableList}
                />
            </CardBody>
        </Card>
      </Paper>
   
  );
}
