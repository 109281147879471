
//Icon
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery50Icon from '@material-ui/icons/Battery50';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';

import { FaMale, FaFemale, FaBaby, FaHandHolding } from "react-icons/fa";
import { MdAccessible } from "react-icons/md";
import { BsPeopleFill, BsSquare, BsSquareFill } from "react-icons/bs";
import {AiFillCheckCircle, AiFillFileUnknown, AiFillQuestionCircle, AiFillExclamationCircle} from "react-icons/ai"

import {MdDownloadForOffline} from "react-icons/md"
import {BsFillCircleFill} from "react-icons/bs"

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export const renderSensorBattery = (status) => {
    switch (status) {
          case "success":
            return <BatteryFullIcon style={{ color:"green" , fontSize: "20" }} />;
          case "warning":
            return <Battery50Icon style={{ color:"orange" , fontSize: "20" }} />;
          case "danger":
            return <Battery20Icon style={{ color:"red" , fontSize: "20" }} />;
          case "login":
            return <BatteryAlertIcon style={{ color:"gray" , fontSize: "20" }}/>
          default:
            return <BatteryAlertIcon style={{ color:"gray" , fontSize: "20" }}/>
    }
  }

export const renderToiletTypeSwitch = (type) => {
    switch (type) {
      case "male":
        return <FaMale style={{ color: "lightBlue", fontSize: "25" }} />;
      case "female":
        return <FaFemale style={{ color: "pink", fontSize: "25" }} />;
      case "zaccessible":
        return <MdAccessible style={{ color: "black", fontSize: "30" }} />;
      case "babycare":
        return <FaBaby style={{ color: "green", fontSize: "25" }} />;
      case "family":
        return <BsPeopleFill style={{ color: "grey", fontSize: "25" }} />;
      case "pantry":
          return <FreeBreakfastIcon style={{ color: "grey", fontSize: "25" }} />;
          
      default:
        return <AiFillFileUnknown style={{ color: "black", fontSize: "30" }}/>
        }
  }

  export const renderToiletStatusSwitch = (status) => {
    switch (status) {
          case "success":
            return <BsFillCircleFill style={{ color:"green" , fontSize: "20" }} />;
          case "warning":
            return <BsFillCircleFill style={{ color:"orange" , fontSize: "20" }} />;
          case "danger":
            return <BsFillCircleFill style={{ color:"red" , fontSize: "20" }} />;
          case "login":
            return <BsFillCircleFill style={{ color:"gray" , fontSize: "20" }}/>
          default:
            return <BsFillCircleFill style={{ color:"gray" , fontSize: "20" }}/>
    }
  }

export const renderToiletDate = (date) => { return <h6>{date}</h6> }

export const renderHeader = (Text) => { return <span style={{fontSize:14}}>{Text}</span> }

export const renderEventDate = (date) => {
    var formattedDate = date.split(" ")
    return (
    <GridContainer >
      <GridItem >
        <h6>{formattedDate[0]}</h6>
      </GridItem>
      <GridItem >
        <h6 style={{color:'blue'}}>{formattedDate[1]}</h6>
      </GridItem>
    </GridContainer>

    )
  }


export default {renderSensorBattery,renderToiletTypeSwitch,renderToiletStatusSwitch,renderToiletDate,renderEventDate,renderHeader}