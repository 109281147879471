import React from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//Firebase
import { useCallback } from 'react'
import firebase from "firebase";
import { getAuth, createUserWithEmailAndPassword , signOut, sendSignInLinkToEmail } from 'firebase/auth'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import PersonIcon from '@material-ui/icons/Person';
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Popper from '@material-ui/core/Popper';

import LoginPage from "views/Pages/LoginPage";

//Password Checker
import {passwordStrength} from 'variables/checkPassword'

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);



export default function ForgetPasswordPage() {
    // register form
    const [registerErrMsg, setregisterErrMsg] = React.useState("")
    const [registerEmail, setregisterEmail] = React.useState("");
    const [registerEmailState, setregisterEmailState] = React.useState("");
    const [registerPassword, setregisterPassword] = React.useState("");
    const [registerPasswordState, setregisterPasswordState] = React.useState("");
    const [registerPasswordStrength, setregisterPasswordStrength] = React.useState("");
    const [registerConfirmPassword, setregisterConfirmPassword] = React.useState("");
    const [registerConfirmPasswordState, setregisterConfirmPasswordState,] = React.useState("");
    const [registerCheckbox, setregisterCheckbox] = React.useState(false);
    const [registerCheckboxState, setregisterCheckboxState] = React.useState("");
    const [registerPasswordHelper, setregisterPasswordHelper] = React.useState("")
    const [redirectTo,setRedirectTo] = React.useState(false)
    // function that returns true if value is email, false otherwise
    const verifyEmail = (value) => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(value)) {
        return true;
      }
      return false;
    };

     // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const verifyPasswordStrength = (value) => {
    if (passwordStrength(value).id >= 2 && value.length <16  && value.length > 7 ) {
      return true;
    }else {

    return false;
    
  
    }
  };

  const registerClick = () => {
    if (registerEmailState === "") {
      setregisterEmailState("error");
    }
    if (registerPasswordState === "") {
      setregisterPasswordState("error");
    }
    if (registerConfirmPasswordState === "") {
      setregisterConfirmPasswordState("error");
    }
    if (registerCheckboxState === "") {
      setregisterCheckboxState("error");
    }

  };

  const verifyForm = () =>{ 
   if(registerEmailState == "success" 
        && registerPasswordState == "success" 
        && registerConfirmPasswordState == "success"){
          return false} 
    else 
          return true
  }

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'http://223.16.216.172:3000/auth/reset-password-page',
    // This must be true.
    handleCodeInApp: true,
    
  };

  
  //handle login submit
  const handleSubmit = (e) => {
        e.preventDefault()
        const { registeremail } = e.target.elements
        
        //console.log("email:" ,registeremail.value)
        const auth = getAuth()
            setregisterErrMsg("Email Sent")
   
            sendSignInLinkToEmail(auth, registeremail.value, actionCodeSettings)
            .then(() => {
               
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', registeremail.value);
                setRedirectTo(true)
                
                // ...
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log("errorCode",errorCode," errorMessage",errorMessage)
                // ...
              });
}

       
        
        
  

  const classes = useStyles();
  if  (redirectTo) { return (<Redirect to='/auth/login'  component={LoginPage}/> )} else {
  return (
   
    <div className={classes.container}>
      <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={6}>
          <Card className={classes.cardSignup}>
            <h4 className={classes.cardTitle}>Forget Password</h4>
            <CardBody>
              <GridContainer justify="center">
                
              <GridItem xs={12} sm={12} md={12}>
        
            
                  <form className={classes.form} onSubmit={handleSubmit}> 
                
                    <CustomInput
                      success={registerEmailState === "success"}
                      error={registerEmailState === "error"}
                      labelText="Email Address *"
                      id="registeremail"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          if (verifyEmail(event.target.value)) {
                            setregisterEmailState("success");
                          } else {
                            setregisterEmailState("error");
                          }
                          setregisterEmail(event.target.value);
                        },
                        type: "email",
                        
                        endAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                       
                      }}
                    />
               
         
                  
                      <span style={{fontSize:10, position:'relative', left:12}}>Click on "Submit" and check your email for the link to reset your password.</span> 
      
     
                      <GridContainer justify="space-between" alignItems="center" direction="row">
                          <GridItem  > 
                          <p></p>
                          </GridItem>
                          <GridItem> 
                            <a href="/auth/login"><h6 >Back</h6></a>
                          </GridItem>
                    </GridContainer>
                    <div className={classes.center}>
                      <h6 style={{color:"red"}}>{registerErrMsg}</h6>
                    </div>
                    <div className={classes.center}>
                    <Button disabled = {(registerEmailState != "success")} type="submit" onClick={registerClick} round style={{backgroundColor:"#b61d22"}}>
                        Submit
                      </Button>
                     
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <BrowserRouter>
                      
          
      </BrowserRouter>
    </div>
    
    );
  }
}
